import axios from 'axios'
import url from '@/url'

export const actions = {
    sendAdminResetEmail({commit}, email) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/admin/passwordreset/send`,email)
                .then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    confirmAdminResetToken({commit},token) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/admin/passwordresettoken/validate`,token)
                .then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    resetAdminPasword({commit},password) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/admin/passwordreset`,password)
                .then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
}
