import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import AOS from "aos";
import "aos/dist/aos.css";
import vuePdf from "vue-pdf";
import BootstrapVue, { BootstrapVueIcons } from "bootstrap-vue";
import Toasted from "vue-toasted";
import VueSessionStorage from "vue-sessionstorage";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "axios";
import moment from "moment";
import { VuejsDatatableFactory } from "vuejs-datatable";
import vueScreen from "vue-screen";

var $ = require("jquery");
window.jQuery = $;
// require('datatables.net');
// require('datatables.net-dt')
// require('datatables.net-responsive')
// require('datatables.net-responsive-dt')
require("./assets/vendor/dataTables.bootstrap4.min.css");
require("datatables.net-bs4");
// require('datatables.net-select-bs4')
// require('datatables.net-buttons-bs4')
let jszip = require("jszip");
let pdfmake = require("pdfmake");
require("datatables.net-buttons/js/buttons.html5.js")(
  window,
  $,
  jszip,
  pdfmake
);
import "../src/assets/css/sb-admin-2.min.css";

Vue.component("multiselect", Multiselect);
Vue.use(VueSessionStorage);
Vue.use(Toasted);
Vue.prototype.$http = axios;
(Vue.config.productionTip = false), Vue.use(VuejsDatatableFactory);
Vue.use(vueScreen);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(vuePdf);
// Vue.use(VueTelInput)

Vue.filter("formatDate", function(value, formatType = "LL") {
  if (!value) return "";
  return moment(value).format(formatType);
});

new Vue({
  created() {
    AOS.init();
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
