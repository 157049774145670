export default {
  key: "super",
  dashboard: {
    component: "MarketIndex",
    links: {
      sidebar: [
        {
          icon: "fas fa-home",
          text: "Dashboard",
          route: "/admin/dashboard",
          active: "dashboard",
        },
        {
          icon: "fas fa-globe-europe",
          text: "Global Admins",
          route: "/super_admin",
          active: "super-admin",
        },
        {
          icon: "fas fa-user-cog",
          text: "Assign Editor",
          route: "/assign_editor",
          active: "assign-editor",
        },
        {
          icon: "fas fa-table",
          text: "Market places",
          route: "/super_admin_market",
          active: "super-admin-market",
        },
        {
          icon: "fas fa-money-check-alt",
          text: "Payments & Transactions",
          route: "/payment_and_transactions",
          active: "token-management",
        },
        {
          icon: "fas fa-dolly-flatbed",
          text: "E-Logistics",
          route: `/e-logistics/adminId`,
          active: "e-logistics",
        },
      ],
      global: [
        {
          title: "Profile Mananagement",
          icon: "far fa-id-card",
          active: false,
          sublinks: [
            {
              icon: "fas fa-sitemap",
              text: "Entities",
              route: "/admins/entities",
              active: "entities",
            },
            {
              icon: "fas fa-users",
              text: "Users",
              route: "/admins/users",
              active: "users",
            },
          ],
        },
        {
          title: "Mandates",
          icon: "fas fa-braille",
          active: false,
          sublinks: [
            {
              text: "Organisations",
              route: "/organisations/give_mandate",
              active: "organisations",
            },
            {
              text: "Agencies",
              route: "/agencies/give_mandate",
              active: "Agencies",
            },
            {
              text: "Outlets",
              route: "/outlets/give_mandate",
              active: "outlets",
            },
          ],
        },
      ],
    },
  },
};
