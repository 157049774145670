import axios from 'axios'
import url from '@/url'
import Storage from '@/helpers/storage.js'
import VueSessionStorage from 'vue-sessionstorage'
import Vue from 'vue'

export const  state = {
    tokens : []
}

export const actions = {
    issueToken({commit},userData) {
        return new Promise((resolve, reject)=> {
            axios.post(`${url()}/token/issue`,userData,
            {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response => {
                    if(response.status === 201) {
                        commit('ADD_TOKEN', response.data)
                    }
                    resolve(response)
                })
                .catch(err => reject(err))
        })
    }, 
    fetchTokens({commit},id) {
        return new Promise((resolve, reject)=> {
            axios.get(`${url()}/entity-tokens/${id}`,{
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response => {
                    if(response.status === 200) {
                        commit('ADD_TOKENS', response.data)
                    }
                    resolve(response)
                })
                .catch(err => reject(err))
        })
    }, 
    sendTokenUnitsToAnotherEntity({commit},tokendata) {
        return new Promise((resolve, reject)=> {
            axios.post(`${url()}/token/send`,tokendata,
            {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response => {
                    resolve(response)
                })
                .catch(err => reject(err))
        })
    }, 
    sendTokenUnitsToIndividual({commit},tokendata) {
        return new Promise((resolve, reject)=> {
            axios.post(`${url()}/token/send-to-individual`,tokendata,
            {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response => {
                    resolve(response)
                })
                .catch(err => reject(err))
        })
    }, 
    // issueCall({commit},tokendata) {
    //     return new Promise((resolve, reject)=> {
    //         axios.post(`${url()}/issue-call`,tokendata,
    //         {
    //             headers: {
    //                 'Authorization':`${Vue.prototype.$session.get('entity')}`
    //             }
    //         }) .then(response => {
    //                 resolve(response)
    //             })
    //             .catch(err => reject(err))
    //     })
    // }, 
    
}

export const mutations = {
    ADD_TOKEN(state, token) {
        state.tokens.unshift(token)
    },
    ADD_TOKENS(state, data){
        state.tokens = data
    }
} 

export const getters = {
    tokens: state => state.tokens
}