import url from "../../url";
import axios from "axios";
import Storage from "@/helpers/storage.js";

export const state = {
  requirements: [],
  additional: [],
  docs: [],
};

export const actions = {
  fetchRequirements({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/e_logistics/read-all-req-type`, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("FETCH_REQUIREMENTS", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchAdditionals({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/e_logistics/read-additional-req/`, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("FETCH_ADDITIONALS", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchDocs({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${url()}/e_logistics/read-docs/${params.regulatorId}/${
            params.typeId
          }`,
          {
            headers: {
              Authorization: `${Storage.get("token")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("FETCH_DOCS", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addRequirement({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url()}/api/e_logistics/add-req-type/`, data, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("ADD_REQUIREMENT", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addAdditional({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url()}/api/e_logistics/add-additional-req/`, data, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("ADD_ADDITIONAL", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addDoc({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url()}/api/e_logistics/add-doc-type/`, data, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("ADD_DOC", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateRequirement({ commit }, requirement) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${url()}/api/e_logistics/edit-req-type/${requirement.id}/`,
          requirement.data,
          {
            headers: {
              Authorization: `${Storage.get("token")}`,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  updateAdditional({ commit }, additional) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${url()}/e_logistics/edit-additional-req/${additional.id}/`,
          additional.data,
          {
            headers: {
              Authorization: `${Storage.get("token")}`,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  updateDoc({ commit }, doc) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${url()}/e_logistics/edit-doc-type/${doc.id}/`, doc.data, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  deleteRequirement({ commit }, requirementId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${url()}/api/e_logistics/delete-req-type/${requirementId}`, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          console.log(response);
          resolve(response);
          commit("DELETE_REQUIREMENT", requirementId);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteAdditional({ commit }, additionalId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${url()}/api/e_logistics/delete-additional-req/${additionalId}`,
          {
            headers: {
              Authorization: `${Storage.get("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          resolve(response);
          commit("DELETE_ADDITIONAL", additionalId);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteDoc({ commit }, docId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${url()}/api/e_logistics/delete-doc-type/${docId}`, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          console.log(response);
          resolve(response);
          commit("DELETE_DOC", docId);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export const mutations = {
  FETCH_REQUIREMENTS(state, requirements) {
    state.requirements = requirements;
  },
  FETCH_ADDITIONALS(state, additionals) {
    state.additionals = additionals;
  },
  FETCH_DOCS(state, docs) {
    state.docs = docs;
  },
  ADD_REQUIREMENT(state, requirement) {
    state.requirements.push(requirement);
  },
  ADD_DOC(state, doc) {
    state.docs.push(doc);
  },
  UPDATE_REQUIREMENT(state, { id, requirement }) {
    // state.requirements = [
    //   ...state.requirements.filter((element) => element.id !== id),
    //   requirement,
    // ];

    const record = state.requirements.find((element) => element.id === id);
    state.requirements[record] = requirement;
  },
  UPDATE_DOC(state, { id, doc }) {
    state.docs = [
      ...state.docs.map((item) =>
        item.id !== id ? item : { ...item, ...doc }
      ),
    ];
  },
  DELETE_REQUIREMENT(state, id) {
    index = state.requirements.findIndex((license) => license.id == id);
    state.requirements.splice(index, 1);
  },
  DELETE_DOC(state, id) {
    index = state.docs.findIndex((license) => license.id == id);
    state.docs.splice(index, 1);
  },
  ADD_ADDITIONAL(state, additional) {
    state.additionals.push(additional);
  },
  UPDATE_ADDITIONAL(state, { id, content }) {
    state.additionals.filter((license) =>
      license.id == id ? (content = license) : null
    );
  },
  DELETE_ADDITIONAL(state, id) {
    index = state.additionals.findIndex((license) => license.id == id);
    state.additionals.splice(index, 1);
  },
};

export const getters = {
  allrequirements: (state) => state.requirements,
  alladditionals: (state) => state.additionals,
};
