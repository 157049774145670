import axios from 'axios'
import url from '@/url.js'
import accountUrl from '../../helpers/url'
import Storage from '@/helpers/storage.js'
import VueSessionStorage from 'vue-sessionstorage'
import Vue from 'vue'
import roles from "../../api/roles"
var convert = require('xml-js')
import service from "../../helpers/service"
import URL from '../../helpers/url'

export const state = {
    token: Storage.get('token') || '',
    dashboard: Storage.get('dashboard') || {},
    currentUser: Storage.get('currentUser') || {}
    
}

export const actions = {
    //admin login
    // adminLogin({commit},data){
    //     return new Promise((resolve, reject)=>{ 
    //         axios.post(`${url()}/admin/login`,data)
    //         .then(response => { 
    //             if(response) {
    //                 const token = response.data.token
    //                 commit('set_token',token)
    //             }
    //             resolve(response)
    //         }).catch(err => {   
    //             reject(err)
    //         })

        
    //     })
    // },
    // admin change password
    changePassword({commit},password){
        return new Promise((resolve,reject) => {
            axios.patch(`${url()}/admin/change_password`,password, {
                headers: {
                 'Authorization':Storage.get('token')
                }
                })
                .then(response => {
                    resolve(response)
                }).then(err => {
                    reject(err)
                })
        })
    },
    // Get entity user details after cas authentication
    getUserDetails({commit}, email) {
        return new Promise((resolve, reject) => {
            axios.get(`${url()}/account/detail/${email}`)
            .then(response => {
                if(response) {

                    const admin = response.data.individual

                    commit('set_currentUser', admin)

                    commit('set_token',admin.email)

                    // find dashboard content
                    let role;
        
                    if(admin.is_superadmin) {

                         role = roles.find( role => role.key === 'super')

                    } else if( admin.is_globaladmin) {

                         role = roles.find( role => role.key === 'global' )

                    } else if(admin.is_marketadmin) {
                        
                         role = roles.find( role => role.key === 'market')

                    } else {

                        role = []

                    }
                    
                    commit('set_dashboard',role)
                }
                resolve(response)
            }).catch(err => reject(err))
        })
    },
    // Entity sign
   validateServiceTicket({commit,dispatch}, ticket) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL()}/accounts/serviceValidate?${service()}&ticket=${ticket}`)
                .then(response => {
                    if(response) {
                        const xml = response.data
                        const data = convert.xml2json(xml, {compact: true, spaces: 4});
                        const user = JSON.parse(data)
                        const email = user["cas:serviceResponse"]["cas:authenticationSuccess"]["cas:user"]._text
                        // this.$session.set('entity', email)
                        axios.defaults.headers.common['Authorization'] = email
                        // Vue.prototype.$session.set('entity',email)
                        //call getUserDetails Action
                       dispatch('getUserDetails',email)
                    }
                resolve(response)
            }).catch( err => reject(err))
        })
   },
    // Admins logout 
    logout({commit}) {
        return new Promise((resolve,reject)=>{
            axios.get(`${accountUrl()}/cas/logout?${service()}`)
                .then(response => {
                    commit('logout')
                    Storage.removeAll()
                    delete axios.defaults.headers.common['Authorization']
                    return window.location.href = `${url()}/accounts/login?${service()}`
                })
            
        })
    },
    setDashboard({commit}, dasboard) {
        commit('set_dashboard', dasboard)
    },
    setCurrentUser({commit},user) {
        commit('set_currentUser',user)

    },
}

export const mutations = {
    logout(state) {
        state.token = ''
        state.dashboard = {}
        state.currentUser = {}
     },
    set_token(state,token) {
        state.token = token
        Storage.set('token',token)
    },
    set_currentUser(state,user) {
        state.currentUser = user
        Storage.set('currentUser',user)
    },
    set_dashboard(state,dashboard) {
        state.dashboard = dashboard
        Storage.set('dashboard',dashboard)
    }
}

export const getters = {
    dashboard: state => state.dashboard,
    currentUser: state => state.currentUser,
    token: state => state.token
}
