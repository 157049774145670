import axios from 'axios'
import url from '@/url'
import Storage from '@/helpers/storage.js'
import VueSessionStorage from 'vue-sessionstorage'
import Vue from 'vue'

export const actions = {
    addEntitiesToAList({commit},userData) {
        return new Promise((resolve, reject)=> {
            axios.post(`${url()}/add-entities-to-list`,userData,
            {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response => {
                    if(response.status === 200) {
                        // commit('ADD_CALL', response.data)
                    }
                    resolve(response)
                })
                .catch(err => reject(err))
        })
    }, 
    addEntitiesToAGroup({commit},userData) {
        return new Promise((resolve, reject)=> {
            axios.post(`${url()}/add-entities-to-groups`,userData,
            {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response => {
                    if(response.status === 200) {
                        // commit('ADD_CALL', response.data)
                    }
                    resolve(response)
                })
                .catch(err => reject(err))
        })
    }, 
    fetchListCreatedCall({commit}, id) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/call-lists/${id}`,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })  
    },
    fetchGroupCreatedCall({commit}, id) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/call-groups/${id}`,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })  
    },
    sendMessageToList({commit},{listID, message}) {
        return new Promise((resolve, reject)=> {
            axios.post(`${url()}/send-message-to-list/${listID}`,message,
            {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response => {
                    if(response.status === 200) {
                        // commit('ADD_CALL', response.data)
                    }
                    resolve(response)
                })
                .catch(err => reject(err))
        })
    }, 
    sendMessageToGroup({commit},{groupID, message}) {
        return new Promise((resolve, reject)=> {
            axios.post(`${url()}/send-message-to-group/${groupID}`,message,
            {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response => {
                    if(response.status === 200) {
                        // commit('ADD_CALL', response.data)
                    }
                    resolve(response)
                })
                .catch(err => reject(err))
        })
    },
    fetchMessagesSentToAGroup({commit}, id) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/messages-sent-to-group/${id}`,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })  
    },
    fetchMessagesSentToList({commit}, id) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/messages-sent-to-list/${id}`,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })  
    },
    fetchListRequest({commit}, id) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/entitys-lists/${id}`,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })  
    },
    groupRequest() {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/entitys-groups/${id}`,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })    
    }
}