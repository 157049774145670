// import store from "@/store/index.js";

// const currentUser = store.getters["currentUser"];

// const user = currentUser.id;

import Storage from "@/helpers/storage";

const user = Storage.get("currentUser").id;

export default {
  key: "global",
  dashboard: {
    component: "MarketIndex",
    links: {
      sidebar: [
        {
          icon: "fas fa-home",
          text: "Dashboard",
          route: "/admin/dashboard",
          active: "dashboard",
        },
        {
          icon: "fas fa-search-dollar",
          text: "Market Admins",
          route: "/global_admin",
          active: "marketadmins",
        },
        {
          icon: "fas fa-user-cog",
          text: "Market Places",
          route: "/gobal_admin_market",
        },
        {
          icon: "fas fa-user-cog",
          text: "Assign Editor",
          route: "/assign_editor",
        },
        {
          icon: "fas fa-money-check-alt",
          text: "Payments & Transactions",
          route: "/payment_and_transactions",
        },
        // {
        //     icon:'fas fa-sitemap',
        //     text:'Entities',
        //     route:'/admins/entities',
        //     active:'entities'
        // },
        // {
        //     icon:'fas fa-users',
        //     text:'Users',
        //     route:'/admins/users',
        //     active:'users'
        // },
        // {
        //     icon:'fa fa-user-cog',
        //     text:'settings',
        //     route:'/settings',
        //     active:'settings'
        // },
      ],
      mandate: {
        title: "Mandates",
        icon: "fas fa-braille",
        active: false,
        sublinks: [
          {
            text: "Organisations",
            route: "/organisations/give_mandate",
            active: "organisations",
          },
          {
            text: "Agencies",
            route: "/agencies/give_mandate",
            active: "Agencies",
          },
          {
            text: "Outlets",
            route: "/outlets/give_mandate",
            active: "outlets",
          },
        ],
      },
    },
  },
};
