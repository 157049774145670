import axios from 'axios'
import url from '@/url'

export const actions = {
    sendResetEmail({commit}, email) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/entity/passwordreset/send`,email)
                .then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    confirmEntityResetToken({commit},token) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/entity/passwordresettoken/validate`,token)
                .then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    resetEntityPasword({commit},password) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/entity/passwordreset`,password)
                .then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },

}
