export default {
  key: "market",
  dashboard: {
    component: "MarketIndex",
    links: {
      sidebar: [
        // {
        //     icon:'fas fa-fw fa-tachometer-alt',
        //     text:'Entity Admins',
        //     route:'/market_admin',
        //     active:'entityadmins'
        // },
        {
          icon: "fas fa-home",
          text: "Dashboard",
          route: "/admin/dashboard",
          active: "dashboard",
        },
        {
          icon: "fas fa-poll",
          text: "Markets",
          route: "/markets",
          active: "markets",
        },
        {
          icon: "fas fa-user-cog",
          text: "Market Places",
          route: "market_admin_market",
        },
        {
          icon: "fas fa-money-check-alt",
          text: "Payments & Transactions",
          route: "/payment_and_transactions",
        },
        // {
        //     icon:'fas fa-sitemap',
        //     text:'Entities',
        //     route:'/admins/entities',
        //     active:'entities'
        // },
        // {
        //     icon:'fas fa-users',
        //     text:'Users',
        //     route:'/admins/users',
        //     active:'users'
        // },
        // {
        //     icon:'fa fa-user-cog',
        //     text:'Settings',
        //     route:'/settings',
        //     active:'settings'
        // }
      ],
      mandate: {
        title: "Mandates",
        icon: "fas fa-braille",
        sublinks: [
          {
            text: "Organisations",
            route: "/organisations/give_mandate",
            active: "organisations",
          },
          {
            text: "Agencies",
            route: "/agencies/give_mandate",
            active: "Agencies",
          },
          {
            text: "Outlets",
            route: "/outlets/give_mandate",
            active: "outlets",
          },
        ],
      },
    },
  },
};
