export default {
  key: "entity",
  dashboard: {
    component: "MarketIndex",
    links: {
      sidebar: [
        {
          icon: "fas home",
          text: "Dashboard",
          route: "/entityadmin/dashboard",
          active: "entity-admin",
        },
        {
          icon: "fas fa-tasks",
          text: "Manage Entities",
          route: "/entityadmin/manageentities",
          active: "manage-entities",
        },

        {
          icon: "fa fa-user-cog",
          text: "settings",
          route: "/settings",
          active: "settings",
        },
      ],
    },
  },
};
