import url from '../../url'
import axios from 'axios' 
import Storage from '@/helpers/storage.js'


export const state = {
    articles:[]
}

export const actions = {
    fetchArtcles({commit}) {
        return new Promise( (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.get(`${url()}/blog/articles`, {
                headers: {
                    'Authorization':`${Storage.get('token')}`
                }
            })
                .then(response => {
                    if(response.status === 200) {
                        commit('ADD_ARTICLES',response.data)
                    }
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    approveOrDisapproveArticle({commit}, {key,id,approve}) {
        console.log(key, id, approve)
        return new Promise((resolve, reject) => {
            axios.patch(`${url()}/blog/approve-disapprove-article/${id}`,approve,{
                headers: {
                    'Authorization': `${Storage.get('token')}`
                }
            })
             .then(response => {
                 if(response.status === 200 ) {
                  commit('UPDATE_ARTICLE', key)   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    },

    deleteArticle({commit},id){
        return new Promise((resolve, reject)=>{
            axios.delete(`${url()}/blog/edit-article/${id}`,{
                headers: {
                    'Authorization': `${Storage.get('token')}`
                }
            })
            .then(response =>{
                console.log(response)
                resolve(response)
                commit('DELETE_ARTICLE',id)
            })
            .catch(err=>{
                reject(err)
            })
            
        })
    }
 }

export const mutations = {
    ADD_ARTICLES(state, articles){
        state.articles = articles
    },
    UPDATE_ARTICLE(state, key) {
        state.articles[key].approved = !state.articles[key].approved
    },
    DELETE_ARTICLE(state, id){
        index = state.articles.findIndex(article => article.id == id)
        state.articles.splice(index,1)
    }
}

export const getters = {
    articles : state => state.articles
}