/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import url from "@/helpers/url.js";
import Storage from "@/helpers/storage.js";
import VueSessionStorage from "vue-sessionstorage";
import service from "../helpers/service";
// importing module files
import * as auth from "@/store/modules/auth.js";
import * as superadmin from "@/store/modules/superadmin.js";
import * as globaladmin from "@/store/modules/globaladmin.js";
import * as marketAdmin from "@/store/modules/marketAdmin.js";
import * as entityadmin from "@/store/modules/entityadmin.js";
import * as entity from "@/store/modules/entity.js";
import * as entityResetPassword from "./modules/entityResetPassword.js";
import * as adminResetPassword from "./modules/adminResetPassword.js";
import * as mandate from "./modules/mandate.js";
import * as entityManageToken from "./modules/EntityTokenManagement";
import * as allAdmins from "./modules/allAdmins";
import * as manageEntities from "./modules/manageEntities";
import * as debate from "./modules/debate";
import * as challenge from "./modules/challenge.js";
import * as article from "./modules/articles";
import * as callManagement from "./modules/callManagement";
import * as marketplace from "./modules/marketplace";
import * as list from "./modules/List";
import * as e_logistics from "./modules/e_logistics";
import * as requirementsAndAdditional from "./modules/requirementsAndAdditional";

var convert = require("xml-js");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: (state) => state.currentUser,
  },
  mutations: {
    set_currentUser(state, user) {
      state.currentUser = user;
      Storage.set("currentUser", user);
    },
  },
  actions: {
    getUserDetails({ commit }, email) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${url()}/account/detail/${email}`)
          .then((response) => {
            if (response) {
              commit("set_currentUser", response.data);
            }
            resolve(response);
          })
          .catch((err) => reject(err));
      });
    },
    validateServiceTicket({ commit, dispatch }, ticket) {
      return new Promise((resolve, reject) => {
        // console.log(`${url()}/accounts/serviceValidate?${service()}&ticket=${ticket}`)
        axios
          .get(
            `${url()}/accounts/serviceValidate?${service()}&ticket=${ticket}`
          )
          .then((response) => {
            if (response) {
              const xml = response.data;
              const data = convert.xml2json(xml, { compact: true, spaces: 4 });
              const user = JSON.parse(data);
              const email =
                user["cas:serviceResponse"]["cas:authenticationSuccess"][
                  "cas:user"
                ]._text;
              axios.defaults.headers.common["Authorization"] = email;
              Vue.prototype.$session.set("entity", email);
              dispatch("getUserDetails", email);
            }
            resolve(response);
          })
          .catch((err) => reject(err));
      });
    },
  },
  // module
  modules: {
    auth,
    superadmin,
    globaladmin,
    marketAdmin,
    entityadmin,
    entity,
    entityResetPassword,
    adminResetPassword,
    mandate,
    entityManageToken,
    allAdmins,
    manageEntities,
    debate,
    challenge,
    article,
    callManagement,
    marketplace,
    list,
    e_logistics,
    requirementsAndAdditional,
  },
});
