import axios from 'axios'
import url from '@/url'
import Storage from '@/helpers/storage.js'
import VueSessionStorage from 'vue-sessionstorage'
import Vue from 'vue'


export const state = {
    myCalls : [],
    myCallRequest: []
}
export const actions = {
    issueCall({commit},userData) {
        return new Promise((resolve, reject)=> {
            axios.post(`${url()}/issue-call`,userData,
            {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response => {
                    if(response.status === 200) {
                        commit('ADD_CALL', response.data)
                    }
                    resolve(response)
                })
                .catch(err => reject(err))
        })
    }, 
    // issue call for application
    fetchCallForApplication({commit}, id) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/issued-calls/${id}`,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    // commit('ADD_CALLS', response.data)
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    fetchCallDetail({commit}, id) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/issued-call/${id}`,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    // commit('ADD_CALLS', response.data)
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    fetchCallRequest({commit}, id) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/issued-call-requests/${id}`, {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    commit('ADD_CALL_REQUEST', response.data)
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    answerCall({commit}, {callID, data}) {
        return new Promise( (resolve, reject) => {
            axios.post(`${url()}/answer-issued-call/${callID}`,data,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    fetchEntitiesOwnAnswers({commit}, {entityID, callID}){
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/entity-answers/${entityID}/${callID}`,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    fetchEntitiesAllAnswer({commit}, id) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/all-answers/${id}`,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })  
    },
    fetchEntitiesThatRespondToACall({commit}, id) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/entites-responded/${id}`,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })  
    },
    fetchOneEntitiesAnswer({commit}, {callID, entityID}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/entity-answers-to-call/${callID}/${entityID}`,
                {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }})
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })  
    },
}

export const mutations = {
    ADD_CALLS(state, calls){
        state.myCalls = calls
    },
    ADD_CALL(state, call ) {
        state.myCalls.unshift(call)
    },
    ADD_CALL_REQUEST(state, request) {
        state.myCallRequest = request   
    }
}

export const getters = {
    myCalls : state => state.myCalls,
    myCallRequest : state => state.myCallRequest
}