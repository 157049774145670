import axios from 'axios'
import url from '@/url'
import Storage from '@/helpers/storage.js'

export const state = {
    markets: [],
    entityAdmins:[]
}

export const actions = {
    createEntityAdmin({commit},data) {
        return new Promise( (resolve, reject) => {
            axios.post(`${url()}/admin/create`,data, 
            {
                headers: {
                'Authorization': `${Storage.get('token')}`
            }}
            )
            .then(response => {
                commit('add_entityadmin',response.data.admin)
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },
    addMarket({commit},market) {
        return new Promise( (resolve, reject) =>{
            axios.post(`${url()}/market/`,market, {headers: {
                'Authorization': `${Storage.get('token')}`
            }})
                .then(response => {
                    if(response.status === 201) {
                        commit('add_market',response.data)
                    }
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    suspendEntityAdmin({commit}, {id, key}) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
            axios.patch(`${url()}/entity-admin-suspend/${id}`)
             .then(response => {
                 if(response.status === 200 ) {
                  commit('updateEntityAdmin', {key:key, data:response.data.admin})   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    },
    unSuspendEntityAdmin({commit}, {id, key}) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
            axios.patch(`${url()}/entity-admin-unsuspend/${id}`)
             .then(response => {
                 if(response.status === 200 ) {
                  commit('updateEntityAdmin', {key:key, data:response.data.admin})   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    }
    ,
    fetchEntityAdmins({commit}) {
        return new Promise( (resolve, reject) =>{
            axios.get(`${url()}/entity-admins`, {headers: {
                'Authorization': `${Storage.get('token')}`
            }})
                .then(response => {
                    if(response.status === 200) {
                        commit('add_entityAdmins',response.data)
                    }
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    getAllMarkets({commit}) {
        return new Promise((resolve,reject) => {
            axios.get(`${url()}/market/`)
                .then(response =>{
                    if(response.status === 200) {
                        commit('add_markets',response.data)
                        resolve(response)
                    }
                }).catch(err => {
                    reject(err)
                })
        }) 
    },
    updateMarket({commit},data){
        return new Promise( (resolve, reject) => {
            axios.put(`${url()}/market/${data.id}/`,data.market, 
            {
                headers: {
                'Authorization': `${Storage.get('token')}`
            }})
                .then(response => {
                    if(response.status === 200) {
                        commit('update_market',{key:data.key,market:response.data})
                    }
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    deleteMarket({commit},data){
        return new Promise( (resolve, reject) => {
            axios.delete(`${url()}/market/${data.id}/`, 
            {
                headers: {
                'Authorization': `${Storage.get('token')}`
            }})
                .then(response => {
                    commit('delete_market',data.key)
                    console.log('key:', data.key)
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
}
export const mutations = {
    add_markets(state, data) {
        state.markets = data
        Storage.set('markets')
    },
    add_market(state,data) {
        state.markets.push(data)
    },
    delete_market(state,key){
       state.markets.splice(key,1)
    },
    update_market(state,{key,market}) {
        state.markets[key] = market
    },
    add_entityAdmins(state,data) {
        state.entityAdmins = data
    },
    add_entityadmin(state, data) {
        state.entityAdmins.push(data)
    },
    updateEntityAdmin(state,{key,data}) {
        state.entityAdmins[key] = data
    }
}
export const getters = {
    markets: state => state.markets,
    entityAdmins: state => state.entityAdmins
}