export default {
    key:'entity_user',
    dashboard: {
        component:'MarketIndex',
        links: {
            sidebar:[
                {
                    icon:'fas fa-fw fa-tachometer-alt',
                    text:'Dashboard',
                    route:'/entity_dashboard',
                    active:'dashboard'
                },
                {
                    icon:'fas fa-drumstick-bite',
                    text:'Token Management',
                    route:'/token_management',
                    active:'token'
                }
            ]
        }
    }
}

