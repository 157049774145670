import url from '../../url'
import axios from 'axios' 
import Storage from '@/helpers/storage.js'


export const state = {
    debateArticles:[],
    critiqueArticle:{}
}

export const actions = {
    fetchDbateArtcles({commit}) {
        return new Promise( (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.get(`${url()}/blog/debate-articles`, {
                headers: {
                    'Authorization':`${Storage.get('token')}`
                }
            })
                .then(response => {
                    if(response.status === 200) {
                        commit('ADD_DEBATE_ARTICLES',response.data)
                    }
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    approveOrDisapproveDebateArticle({commit}, {key,id,approve}) {
        console.log(key, id, approve)
        return new Promise((resolve, reject) => {
            axios.patch(`${url()}/blog/approve-disapprove-debate-article/${id}`,approve,{
                headers: {
                    'Authorization': `${Storage.get('token')}`
                }
            })
             .then(response => {
                 if(response.status === 200 ) {
                  commit('UPDATE_DEBATE', key)   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    },
    approveOrDisapproveThesis({commit}, {id, key,articleKey,approve}) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.patch(`${url()}/blog/approve-disapprove-thesis/${id}`,approve)
             .then(response => {
                 if(response.status === 200 ) {
                //   commit('APPROVE_DEBATE_ARTICLE', {articleKey:articleKey,key:key})   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    },
    approveOrDisapproveAntiThesis({commit}, {id,approve}) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.patch(`${url()}/blog/approve-disapprove-antithesis/${id}`, approve)
             .then(response => {
                 if(response.status === 200 ) {
                //   commit('UPDATE_DEBATE',key)   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    },
    approveOrDisapproveSynthesis({commit}, {id,approve}) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.patch(`${url()}/blog/approve-disapprove-synthesis/${id}`, approve)
             .then(response => {
                 if(response.status === 200 ) {
                //   commit('UPDATE_DEBATE',key)   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    },
    deleteCritique({commit},id){
        return new Promise((resolve,reject)=>{
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.patch(`${url()}/blog/edit-debate-antithesis/${id}/`)
            .then(response=>{
                if(response.status === 200){
                    console.log('your task was successful')
                }
                resolve(response)
                commit("DELETE_CRITIQUE",id)
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    deleteDebate({commit},id){
        return new Promise((resolve,reject)=>{
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.delete(`${url()}/blog/edit-debate/${id}/`)
            .then(response=>{
                if(response.status === 200){
                    console.log('your task was deleted successful')
                }
                commit("DELETE_DEBATE",id)
                resolve(response)
            })
            .catch(err=>{
                reject(err)
            })
        })
    }
}


export const mutations = {
    ADD_DEBATE_ARTICLES(state, articles){
        state.debateArticles = articles
    },
    UPDATE_DEBATE(state, key) {
        state.debateArticles[key].approved = !state.debateArticles[key].approved
    },
    APPROVE_DEBATE_ARTICLE(state,{articleKey,key}){
        state.debateArticles[articleKey].thesis[key].approved = !state.debateArticles[articleKey].thesis[key].approved
    },
    DELETE_CRITIQUE(state,id){
        index = state.critiqueArticle.findIndex(critique => critique.id == id)
        state.critiqueArticle.remove()
    },
    DELETE_DEBATE(state,id){
        index = state.debateArticles.findIndex(debate => debate.id == id)
        state.debateArticles.splice(index,1)
    },
    
}

export const getters = {
    debateArticles : state => state.debateArticles,
    // debateArticle: (state) => (id) => state.debateArticles.find(debate => debate.id == id)
}