import axios from 'axios'
import url from '@/url'
import Storage from '@/helpers/storage.js'

export const state = {
    unApprovedEntities:[]
}

export const actions = {
    addEntity({commit},entity) {
        return new Promise( (resolve, reject) => {
            axios.post(`${url()}/entity/`,entity,
            {
                headers: {
                'Authorization': `Token ${Storage.get('token')}`
            }})
                .then(response => {
                    if(response.status === 201) {
                        commit('set_entity', response.data)
                    }
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    updateEntity({commit},data) {
        const entity = {
            name:data.name,
            email:data.email,
            type:data.type,
            market:data.market,
            phone:data.phone
        }
        return new Promise((resolve,reject)=> {
            axios.put(`${url()}/entity/${data.id}/`, entity, {
                headers: {
                'Authorization': `Token ${Storage.get('token')}`
                }})
                .then(response => {
                    commit('update_entity',{key:data.key,entity:response.data} )
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    fetchAllUnapprovedEntities({commit}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/entities_unapproved/`)
                .then(response => {
                    console.log('data', response)
                    commit('set_unApprovedEntities',response.data)
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    approveEntity({commit},data) {
        return new Promise( (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = `Token ${Storage.get('token')}`
            axios.patch(`${url()}/entity/approve/${data.id}`)
                .then(response => {
                    commit('approveAfcfta',{key:data.key, data:response.data})
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
  
}
export const mutations = {
    set_entity(state, entity) {
        state.entities.push(entity)
    },
    set_entities(state,entities) {
        state.entities = entities
    },
    delete_entity(state,key) {
        state.entities.splice(key,1)
    },
    update_entity(state,{key,entity}) {
        state.entities[key] = entity
    },
    set_unApprovedEntities(state,data) {
        state.unApprovedEntities = data
    },
    approveAfcfta(state,{key,data}) {
        state.unApprovedEntities.splice(key,1)
        state.entities[data.key] = data
    }
}

export const getters = {
    unApprovedEntities: state => state.unApprovedEntities
}