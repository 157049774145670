import axios from 'axios'
import url from '@/url'
import Storage from '@/helpers/storage.js'


export const state = {
    superadminmarket:[],
    globaladminmarket:[],
    marketadmin:[],
    marketPlaces:[]
}
// actions
export const actions = {
    fetchSuperAdminMarket({commit}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/marketplace/super-admin-marketplace/`,
            {headers: {
                'Authorization': `${Storage.get('token')}`
            }}).then(response => {
                    if(response.status === 200) {
                        commit('SET_MARKET', response.data)
                    }
                    resolve(response)
                    console.log(response.data)
                }).catch( err => reject(err))
        })
    },
   
    addSuperAdminMarket({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.post(`${url()}/marketplace/super-admin-marketplace/`, data)
                .then(response => {
                    commit('ADD_SUPERADMIN_MARKETPLACE', response.data)
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    fetchGlobalAdminMarket({commit}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/marketplace/global-admin-marketplace/`,
            {headers: {
                'Authorization': `${Storage.get('token')}`
            }}).then(response => {
                    if(response.status === 200) {
                        commit('SET_GLOBAL_ADMIN', response.data)
                    }
                    resolve(response)
                    console.log(response.data)
                }).catch( err => reject(err))
        })
    },
   
    addGlobalAdminMarket({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.post(`${url()}/marketplace/global-admin-marketplace/`, data)
                .then(response => {
                    commit('ADD_GLOBALADMIN_MARKETPLACE', response.data)
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    fetchMarketAdmin({commit}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/marketplace/market-admin-marketplace/`,
            {headers: {
                'Authorization': `${Storage.get('token')}`
            }}).then(response => {
                    if(response.status === 200) {
                        commit('SET_MARKET_ADMIN', response.data)
                    }
                    resolve(response)
                    console.log(response.data)
                }).catch( err => reject(err))
        })
    },
   
    addMarketAdmin({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.post(`${url()}/marketplace/market-admin-marketplace/`, data)
                .then(response => {
                   commit('ADD_MARKETADMIN_MARKETPLACE', response.data)
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    fetchAllMarketPlaces({commit}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/marketplace/marketplaces`)
                .then( response => {
                    commit('ADD_MARKET_PLACES', response.data)
                    resolve(response)
                }).catch( err => reject(err))
        })
    }
   
   
}       
// mutations
export const mutations = {
    ADD_MARKET_PLACES(state, data) {
        state.marketPlaces = data
    },
    SET_MARKET(state,market) {
        state.superadminmarket = market
    },
    ADD_SUPERADMIN_MARKETPLACE(state, data) {
        state.superadminmarket.push(data)
    },
    ADD_GLOBALADMIN_MARKETPLACE(state, data) {
        state.globaladminmarket.push(data)
    },
    ADD_MARKETADMIN_MARKETPLACE(state, data) {
        state.marketadmin.push(data)
    },
    SET_GLOBAL_ADMIN(state, market){
        state.globaladminmarket = market
    },
    SET_MARKET_ADMIN(state, market){
        state.marketadmin= market
    }
    
    
}
// GETTERS
export const getters = {
    marketPlaces : state => state.marketPlaces
}
