import axios from 'axios'
import url from '@/url'
import Storage from '@/helpers/storage.js'

export const state = {
    globalAdmins: [],
    editors:[]
}

export const actions = {
    createGlobalAdmin({commit},data) {
         return new Promise((resolve,reject)=> {
             axios.post(`${url()}/admin/create`,data, {
                headers: {
                 'Authorization':Storage.get('token')
                }
            })
                .then(response => {
                    if(response) {  
                        const admin = response.data.admin
                        commit('add_globaladmin',admin)
                     }
                     resolve(response)
                }).catch(err => {
                    reject(err)
                })
         })
       },

    getGlobalAdmins({commit}){
       return new Promise((resolve, reject) => {
           axios.get(`${url()}/global-admins`, {
               headers: {
                'Authorization':Storage.get('token')
               }
           })
            .then(response => {
                if(response.status === 200 ) {
                 commit('add_globaladmins', response.data)   
                }
                resolve(response)
            }).catch(err => {
                console.log(err)
                reject(err)
            })
       })
    },
    suspendGlobalAdmin({commit}, {id, key}) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
            axios.patch(`${url()}/global-admin-suspend/${id}`)
             .then(response => {
                 if(response.status === 200 ) {
                  commit('updateGlobalAdmin', {key:key, data:response.data.admin})   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    },
    unSuspendGlobalAdmin({commit}, {id, key}) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
            axios.patch(`${url()}/global-admin-unsuspend/${id}`)
             .then(response => {
                 if(response.status === 200 ) {
                  commit('updateGlobalAdmin', {key:key, data:response.data.admin})   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 

    },
    assignEditor({commit}, id) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
            axios.patch(`${url()}/assign-editor/${id}`)
             .then(response => {
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    },
    getEditors({commit}){
        return new Promise((resolve, reject) => {
            axios.get(`${url()}/editors`, {
                headers: {
                 'Authorization':Storage.get('token')
                }
            })
             .then(response => {
                commit('add_editors', response.data) 
                    resolve(response)
                     }
                 
             ).catch(err => {
                 console.log(err)
                 reject(err)
             })
        })
     },
}

export const mutations = {
    add_editors(state, data){
        state.editors = data
    },
    add_globaladmin(state,admin) {
        state.globalAdmins.push(admin)

    },
    add_globaladmins(state,admins){
        state.globalAdmins = admins
    
    },
    updateGlobalAdmin(state,{key,data}) {
        state.globalAdmins[key] = data
    }
}
export const getters = {
    globalAdmins: state => state.globalAdmins,
    editors: state => state.editors
}