import Vue from "vue";
import VueRouter from "vue-router";
import url from "../helpers/url";
import service from "../helpers/service";
import store from "../store";
import storage from "../helpers/storage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/new/dashboard",
    name: "dashboad",
    component: () => import("../admin/dashboard/index.vue"),
  },
  {
    path: "/",
    name: "admin-dashboard",
    redirect: "/admin/dashboard",
    component: () =>
      import(
        /* webpackChunkName: "admindashboard" */ "@/admin/AdminDashBoard.vue"
      ),
    children: [
      {
        path: "/admin/dashboard",
        name: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "superadmin" */ "@/admin/AllDashboard.vue"
          ),
      },
      {
        path: "/super_admin",
        name: "super-admin",
        component: () =>
          import(
            /* webpackChunkName: "superadmin" */ "@/admin/supadmin/SuperAdmin.vue"
          ),
      },
      {
        path: "/global_admin",
        name: "global_admin",
        component: () =>
          import(
            /* webpackChunkName: "globaladmin" */ "@/admin/globaladmin/GlobalAdmin.vue"
          ),
      },
      {
        path: "/market_admin",
        name: "market_admin",
        component: () =>
          import(
            /* webpackChunkName: "marketadmin" */ "@/admin/marketadmin/MarketAdminHome.vue"
          ),
      },
      {
        path: "/markets",
        name: "markets",
        component: () =>
          import(
            /* webpackChunkName: "markets" */ "@/admin/marketadmin/Markets.vue"
          ),
      },
      {
        path: "/super_admin_market",
        name: "super-admin-market",
        component: () => import("@/admin/supadmin/marketplace.vue"),
      },
      {
        path: "/market_admin_market",
        name: "market_admin_market",
        component: () => import("../admin/marketadmin/marketplace.vue"),
      },
      {
        path: "/gobal_admin_market",
        name: "global-admin",
        component: () => import("@/admin/globaladmin/marketplace.vue"),
      },
      {
        path: "/entityadmin/manageentities",
        name: "entity_admin",
        component: () =>
          import(
            /* webpackChunkName: "entity_admin" */ "../admin/entityadmin/EntityAdmin.vue"
          ),
      },
      {
        path: "/entityadmin/dashboard",
        name: "entityadmin-dashboard",
        component: () =>
          import(
            /* webpackChunkName: "final_afctnumber" */ "../admin/entityadmin/AssignFinalAFCFTANumber.vue"
          ),
      },

      {
        path: "/settings",
        name: "settings",
        component: () =>
          import(
            /* webpackChunkName: "changepassword" */ "../admin/ChangePassword.vue"
          ),
      },
      // superadmins, globaladmins and marketadmins give mandates
      {
        path: "/organisations/give_mandate",
        name: "admins_give_organisation_mandate",
        component: () =>
          import(
            /* webpackChunkName: "resetPaswsord" */ "@/admin/mandates/Organisations.vue"
          ),
      },
      {
        path: "/agencies/give_mandate",
        name: "admins_give-agencies_mandate",
        component: () =>
          import(
            /* webpackChunkName: "resetPaswsord" */ "@/admin/mandates/Agencies.vue"
          ),
      },
      {
        path: "/outlets/give_mandate",
        name: "admins_give_outlets_mandate",
        component: () =>
          import(
            /* webpackChunkName: "resetPaswsord" */ "@/admin/mandates/Outlets.vue"
          ),
      },
      {
        path: "/admins/entities",
        name: "entities",
        component: () =>
          import(
            /* webpackChunkName: "resetPaswsord" */ "@/admin/alldmins/Entities.vue"
          ),
      },
      {
        path: "/admins/users",
        name: "users",
        component: () =>
          import(
            /* webpackChunkName: "resetPaswsord" */ "@/admin/alldmins/Users.vue"
          ),
      },
      {
        path: "/blog/debate-articles",
        name: "debate-articles",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/debate/debateHome.vue"
          ),
      },
      {
        path: "/blog/debate-articles/:id/contributions",
        name: "debate-contributions",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/debate/DebateContributions.vue"
          ),
      },
      {
        path: "/blog/challenge",
        name: "challenge",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/challenge/ChallengeHome.vue"
          ),
      },
      {
        path: "/blog/challenge/:challengeID/contributions",
        name: "challenge-contributions",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/challenge/challengeContiributions.vue"
          ),
      },
      {
        path: "/blog/articles",
        name: "challenge-articles",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/articles/articlesHome.vue"
          ),
      },
      {
        path: "/payment_and_transactions",
        name: "token-management",
        component: () =>
          import(/* webpackChunkName: "token" */ "../admin/token/Token.vue"),
      },
      {
        path: "/assign_editor",
        name: "assign-editor",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/editor/editorHomeComponent.vue"
          ),
      },
      {
        path: "/editors",
        name: "editors",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/editor/editorsComponent.vue"
          ),
      },
      {
        path: "/e-logistics/:id",
        name: "e-logistics",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/supadmin/E-logistics/E-logistics.vue"
          ),
      },
      {
        path: "/e-logistics/:id/customize-license-form",
        name: "customize-license-form",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/supadmin/E-logistics/CustomizeForm.vue"
          ),
      },
      {
        path: "/e-logistics/:id/license-requirements/:category_id",
        name: "customize-license-requirements",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/supadmin/E-logistics/LicenseCategories/LicenseRequirements.vue"
          ),
      },

      {
        path: "/e-logistics/:id/license-categories/:logisticId",
        name: "license-categories",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/supadmin/E-logistics/LicenseCategories/LicenseCategories.vue"
          ),
      },
      {
        path: "/e-logistics/:id/license-forms/:categoryId",
        name: "license-forms",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/supadmin/E-logistics/LicenseForms/LicenseForms.vue"
          ),
      },
      {
        path: "/e-logistics/:id/requirement-types/:logisticId",
        name: "requirement-types",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/supadmin/E-logistics/RequirementTypes/RequirementTypes.vue"
          ),
      },
      {
        path: "/e-logistics/:id/requirement/:typeId/docs/:requirementId",
        name: "requirement-docs",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/supadmin/E-logistics/RequirementTypes/Docs/Docs.vue"
          ),
      },
      {
        path: "/e-logistics/:id/additional-requirements/:logisticId",
        name: "additional-types",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/supadmin/E-logistics/AdditionalTypes/AdditionalTypes.vue"
          ),
      },
      {
        path: "/e-logistics/:id/license-form-file/:file",
        name: "form-pdf",
        component: () =>
          import(
            /* webpackChunkName: "token" */ "../admin/supadmin/E-logistics/LicenseForms/PdfFile.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// redirecting this endpoint to admin.dashboard
router.beforeEach((to, from, next) => {
  const ticket = to.query.ticket;
  const token = storage.get("token");
  if ((to.path === "/" || to.path == "/admin/dashboard") && ticket) {
    store.dispatch("validateServiceTicket", ticket);
    const dashboard = storage.get("dashboard");
    let index = dashboard.dashboard.links.sidebar[0].route;
    next(index);
  }

  if ((to.path === "/" || to.path == "/admin/dashboard") && !ticket && !token) {
    return (window.location.href = `${url()}/accounts/login?${service()}`);
  }
  next();
});

export default router;
