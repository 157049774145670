<template>
  <div id="app" class="container-fluid">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'APP',
 
}
</script>
<style scoped>
html, body {
  margin:0;
  padding:0px;
}
#app{
    font-family: Century Gothic ;
    width:100% ;
    margin:0;
    padding:0;
    /* white-space: pre-wrap !important; */
    /* border:1px solid yellow; */
  }
 
</style>