import axios from 'axios'
import url from '../../url'
import VueSessionStorage from 'vue-sessionstorage'
import Vue from 'vue'
export const state = {
    usersInEntity : [],
    entityDataProviders:[]
}
export const actions = {
    fetchEntityDataproviders({commit},id) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/allowed_data_providers/${id}`,
            {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }
            ).then( response => {
                    if(response.status === 200) {
                        commit('ADD_ENTITYDATAPROVIDERS', response.data.data_providers)
                    }

                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    getUsersInEntity({commit}, id) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/individuals/${id}`,
            {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            } 
            )
                .then(response => {
                    if(response.status === 200) {
                        commit('ADD_USERS_TO_MYUSERS',response.data)
                    }
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    AddUsersToEntity({commit},data) {
        return new Promise( (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = `${Vue.prototype.$session.get('entity')}`
            axios.post(`${url()}/add-users-to-entity`,data)
                .then(response => {
                    if(response.status === 200) {
                        commit('ADD_USERS_TO_MYUSERS',response.data)
                    }
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    allowEntityProfileupdate({commit},data) {
        return new Promise((resolve, reject)=> {
            axios({
                method:'post',
                url:`${url()}/allow_profile_update`,
                data:data,
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
            .then(response => {
                // commit('ALLOW_DATAPROVIDER',data.key)
                resolve(response)
            })
            .catch(err => reject(err))
        })
    },
    disallowEntityProfileupdate({commit},data) {
        return new Promise((resolve, reject)=> {
            axios({
                method:'post',
                url:`${url()}/disallow_profile_update`,
                data:{
                    "data_provider_id": data.data_provider_id,
                    "entity_id" :data.entity_id
                    },
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
            .then(response => {
                commit('DISALLOW_ENTITY_DATAPROVIDER',data.key)
                resolve(response)
            })
            .catch(err => reject(err))
        })
    },
}

export const mutations = {
    ADD_USERS_TO_MYUSERS(state, users) {
        state.usersInEntity = users
    },
    ADD_ENTITYDATAPROVIDERS(state, dataproviders) {
        state.entityDataProviders = dataproviders
    },
    DISALLOW_ENTITY_DATAPROVIDER(rootState, key){
        state.entityDataProviders.splice(key,1)
    }
}

export const getters = {
    myUsers: state => state.usersInEntity,
    entityDataProviders: state => state.entityDataProviders,
}
