import axios from 'axios'
import url from '@/url'
import Storage from '@/helpers/storage.js'
import VueSessionStorage from 'vue-sessionstorage'
import Vue from 'vue'
import compareArray from '../../helpers/compareArrays'



// const header = {
//     headers: {
//         'Authorization':`${Vue.prototype.$session.get('entity')}`
//     }
// }
//state
export const state = {
    guaranteeRequests: [],
    profileupdateapis:[],
    myCollections:[],
    dataproviders:[],
    myDataProviders:[],
    users:[],
    marketplaces:[],
    market:[],
    mymarketplace:[],
    entityproducts:[]
}
// actions
export const actions = {
    fetchCollections({commit}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/profile_update_apis`)
                .then(response => {
                    if(response.status === 200) {
                        commit('ADD_COLLECTIONS', response.data)
                    }
                    resolve(response)
                }).catch( err => reject(err))
        })
    },
    fetchUsers({commit}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/profile_update_apis`)
                .then(response => {
                    if(response.status === 200) {
                        commit('ADD_COLLECTIONS', response.data)
                    }
                    resolve(response)
                }).catch( err => reject(err))
        })
    },
    profileUpdateApis({commit}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/profile_update_apis`)
                .then( response => {
                    if(response.status === 200) {
                        commit('ADD_PROFILE_UPDATE_APIS', response.data)
                    }
                    resolve(response)
                }).catch( err => {
                    reject(err)
                })
        })
    },
    addApiToCollection({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = Storage.get('token')   
            axios.post(`${url()}/add_api_to_collection`, data)
                .then( response => {
                    if(response.status === 201) {
                        commit('ADD_COLLECTIONS', response.data)
                    }
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    addProductRegisteration({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.post(`${url()}/add_product_registeration`, data)
                .then(response => {
                    // if(response.status === 201) {
                    //     commit('')
                    // }
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    addDataSource({commit}, userData) {
        return new Promise((resolve, reject)=> {
            axios.post(`${url()}/data_source`,userData, {
                headers: {
                    'Authorization':Storage.get('token') 
                }
            })
            .then(response => {
                resolve(response)
            }).catch(err => reject(err))
        })
    }, 
    fetchGuaranteeRequests({commit}) {
        return new Promise((resolve, reject)=> {
            axios.get(`${url()}/gurantor/requests`)
                .then(response => {
                    if(response.status === 200) {
                        commit('set_guaranteeRequests',response.data)
                    }
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    approvedGurantorRequest({commit},id) {
        return new Promise((resolve, reject)=> {
            axios.patch(`${url()}/gurantor/req/approve`,id)
                .then(response => resolve(response))
                .catch(err => reject(err))
        })
    },
    fetchDataproviders({commit}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/data_providers`,
            {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }
            )
                .then( response => {
                    // console.log(response)
                    if(response.status === 200) {
                        commit('ADD_DATAPROVIDERS', response.data)
                    }

                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    fetchMyDataproviders({commit}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/allowed_data_providers_individual`,
            {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }
            ).then( response => {
                    if(response.status === 200) {
                        commit('ADD_MYDATAPROVIDERS', response.data.data_providers)
                    }

                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    allowProfileupdate({commit},data) {
        return new Promise((resolve, reject)=> {
            axios({
                method:'post',
                url:`${url()}/allow_profile_update/individual`,
                data:{
                    "data_provider_id":data.id
                    
                },
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
            .then(response => {
                commit('ALLOW_DATAPROVIDER',data.key)
                resolve(response)
            })
            .catch(err => reject(err))
        })
    },
    disallowProfileupdate({commit},data) {
        return new Promise((resolve, reject)=> {
            axios({
                method:'post',
                url:`${url()}/disallow_profile_update/individual`,
                data:{
                    "data_provider_id":data.id
                    
                },
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
            .then(response => {
                commit('DISALLOW_DATAPROVIDER',data.key)
                resolve(response)
            })
            .catch(err => reject(err))
        })
    },
    entityRequest({commit},id) {
        return new Promise((resolve, reject)=> {
            axios({
                method:'get',
                url:`${url()}/request-add-to-entity/${id}`,
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
            .then(response => {
                resolve(response)
            })
            .catch(err => reject(err))
        })
    },
    GetAllCreatedMarketplaces({commit}){
        return new Promise((resolve, reject)=>{
            axios({
                method:'get',
                url:`${url()}/marketplace/marketplaces`,
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
            .then(response=>{
                resolve(response)
                commit('SET_ALL_MARKET_PLACES', response.data)
                // console.log(response.data)
            })
            .catch(err => reject(err))
        })
        
    },
    addProductToMarket({commit},data){
        return new Promise((resolve,reject)=>{
            axios({
                method:'post',
                url:`${url()}/marketplace/products/add/`,data,
                    headers: {
                        'Authorization':`${Vue.prototype.$session.get('entity')}`
                    }
                
            }).then(response=>{
                commit('ADD_PRODUCT_TO_MARKET',response)
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },

    myMarketPlace({commit}, id){
        return new Promise((resolve,reject)=>{
            axios({
                method:'Get',
                url:`${url()}/marketplace/fetch-market-places/${id}`,
                headers:{
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response=>{
                commit('SET_MY_MARKET_PLACE',response)
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    entityProducts({commit}, id){
        return new Promise((resolve,reject)=>{
            axios({
                method:'Get',
                url:`${url()}/marketplace/fetch-entity-products/${id}/`,
                headers:{
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response=>{
                commit('SET_ENTITY_PRODUCTS',response)
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    deleteProduct({commit}, id){
        return new Promise((resolve,reject)=>{
            axios({
                method:'delete',
                url:`${url()}/marketplace/products/${id}`,
                headers:{
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response=>{
                resolve(response)
                commit("DELETE_PRODUCT",id)
            }).catch(err=>{
                reject(err)
            })
        })

    },
    updateProduct({commit},{id,data}){
        return new Promise((resolve,reject)=>{
            axios({
                method:'PATCH',
                url:`${url()}/marketplace/products/${id}/`,data,
                    headers: {
                        'Authorization':`${Vue.prototype.$session.get('entity')}`
                    }
                
            })
            .then(response=>{
                resolve(response)
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    createEntity({commit}, data){
        return new Promise((resolve,reject)=>{
            axios({
                method:'post',
                url:`${url()}/entity-account-create`,data,
                    headers: {
                        'Authorization':`${Vue.prototype.$session.get('entity')}`
                    }
                
            }).then(response=>{
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    updateEntityProfile({commit}, data){
        return new Promise((resolve,reject)=>{
            axios({
                method:'put',
                url:`${url()}/entity-update`,data,
                    headers: {
                        'Authorization':`${Vue.prototype.$session.get('entity')}`
                    }
                
            }).then(response=>{
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    profileStatus({commit}, {id, data}) {
        return new Promise((resolve, reject) => {
         axios.patch(`${url()}/change-profile-status/${id}`,data,
         {
            headers: {
                'Authorization':`${Vue.prototype.$session.get('entity')}`
            }
         }
         )
         .then(response => { 
             resolve(response)
         }).catch(err => {   
             reject(err)
         })
        })
    },
}       
// mutations
export const mutations = {
    set_guaranteeRequests(state,requests) {
        state.guaranteeRequests = requests
    },
    ADD_COLLECTIONS(state, collection) {
        state.myCollections = collection
    },
    ADD_PROFILE_UPDATE_APIS(state, apis) {
        state.profileupdateapis = apis
    },
    ADD_DATAPROVIDERS(state, dataproviders) {
        state.dataproviders = dataproviders
    },
    ADD_MYDATAPROVIDERS(state, dataproviders){
        state.myDataProviders = dataproviders
    },
    ALLOW_DATAPROVIDER(state, key){
        state.dataproviders.splice(key, 1)
    },
    DISALLOW_DATAPROVIDER(state,key) {
        state.myDataProviders.splice(key, 1)
    },
    SET_ALL_MARKET_PLACES(state,marketplaces){
        state.marketplaces = marketplaces
    },
    ADD_PRODUCT_TO_MARKET(state,market){
        state.market = market
    },
    SET_MY_MARKET_PLACE(state,mymarketplace){
        state.mymarketplace = mymarketplace
    },
    SET_ENTITY_PRODUCTS(state,entityproducts){
        state.entityProducts = entityproducts
    },
    DELETE_PRODUCT(state,product){
        index = state.entityProducts.findIndex(product = product.id == id)
        state.entityProducts.splice(index,1)
    }
}

export const getters = {
    guaranteeRequests: state => state.guaranteeRequests,
    profileupdateapis: state => state.profileupdateapis,
    myCollections: state => state.myCollections,
    dataproviders: state => state.dataproviders,
    myDataProviders: state => state.myDataProviders,
    marketplaces: state => state.marketplaces
}