import axios from 'axios'
import url from '@/url.js'
import Storage from '@/helpers/storage.js'

export const state = {
    organistions: [],
    agencies:[],
    outlets:[],
}

export const actions = {
    // authorization header
    // fetching organisations, outlets and agencies
    fetchOrganisations({commit}) {
        return new Promise((resolve, reject) => {
            
            axios.get(`${url()}/organisations`,{
                headers: {
                    'Authorization': `${Storage.get('token')}`
                }
            }).then(response => {
                    if(response.status === 200) {
                        commit('ADD_ORGANISATIONS', response.data)
                    }
                    resolve(response)
                }).catch( err => reject(err))
        })
    },
    fetchAgencies({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`${url()}/agencies`, {
                headers: {
                    'Authorization': `${Storage.get('token')}`
                }
            })
                .then(response => {
                    if(response.status === 200) {
                        commit('ADD_AGENCIES', response.data)
                    }
                    resolve(response)
                }).catch( err => reject(err))
        })
    },
    fetchOutlets({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`${url()}/outlets`, {
                headers: {
                    'Authorization': `${Storage.get('token')}`
                }
            })
                .then(response => {
                    if(response.status === 200) {
                        commit('ADD_OUTLETS', response.data)
                    }
                    resolve(response)
                }).catch( err => reject(err))
        })
    },
    // giving mandates to organisations, outlets and agencies
    giveMandateToOrganisation({commit},mandate) {
        return new Promise((resolve, reject)=>{
            axios.post(`${url()}/organisation/add-mandate`,mandate,{
                headers: {
                    'Authorization': `${Storage.get('token')}`
                }
            })
            .then(response => {

                resolve(response)
            }).catch( err => reject(err))
    })
    },
    giveMandateToAgency({commit},mandate) {
        return new Promise((resolve, reject)=>{
            axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
            axios.post(`${url()}/agency/add-mandate`,mandate)
            .then(response => {
                resolve(response)
            }).catch( err => reject(err))
    })
    } 


}

export const mutations = {
    ADD_AGENCIES(state, agencies) {
        state.agencies = agencies
    },
    ADD_ORGANISATIONS(state, organistions){
        state.organistions = organistions
    },
    ADD_OUTLETS(state, outlets) {
        state.outlets = outlets
    }
}

export const getters = {
    organisations: state => state.organistions,
    outlets: state => state.outlets,
    agencies: state => state.agencies,
}

