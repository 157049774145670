import url from '../../url'
import axios from 'axios' 
import Storage from '@/helpers/storage.js'

export const state = {
    leadersVisions:[]
}
export const actions = {
    fetchDebateLeadersVisions({commit}) {
        return new Promise( (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.get(`${url()}/blog/fetch-all-leaders-vision`)
                .then(response => {
            
                        commit('ADD_DEBATE_ARTICLES',response.data)
                    
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    approveOrDisapproveChallengeLeadersVisions({commit}, {key,id,approve}) {
        console.log(key, id, approve)
        return new Promise((resolve, reject) => {
            axios.patch(`${url()}/blog/approve-disapprove-leader-vision/${id}`,approve,{
                headers: {
                    'Authorization': `${Storage.get('token')}`
                }
            })
             .then(response => {
                 if(response.status === 200 ) {
                  commit('UPDATE_CHALLENGE', key)   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    },
  
    approveOrDisapproveChallengeCritique({commit}, {key,id,approve}) {
        // console.log(key, id, approve)
        return new Promise((resolve, reject) => {
            axios.patch(`${url()}/blog/approve-disapprove-thinkers-critique/${id}`,approve,{
                headers: {
                    'Authorization': `${Storage.get('token')}`
                }
            })
             .then(response => {
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    }, 
    approveOrDisapproveChallengePlan({commit}, {key,id,approve}) {
        console.log(key, id, approve)
        return new Promise((resolve, reject) => {
            axios.patch(`${url()}/blog/approve-disapprove-enterpreneurs-plan/${id}`,approve,{
                headers: {
                    'Authorization': `${Storage.get('token')}`
                }
            })
             .then(response => {
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    },
}
export const mutations = {
    ADD_DEBATE_ARTICLES(state, data) {
        state.leadersVisions = data
    },
    UPDATE_CHALLENGE(state,key) {
        state.leadersVisions[key].approved = !state.leadersVisions[key].approved
    }
}
export const getters = {
    leadersVisions : state => state.leadersVisions,
 }