import url from "../../url";
import axios from "axios";
import Storage from "@/helpers/storage.js";

export const state = {
  logistics: [],
  categories: [],
  forms: [],
};

export const actions = {
  fetchLogistics({ commit }, entityId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/e_logistics/logistic-regulators/${entityId}`, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("FETCH_LOGISTICS", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchLicenseCategories({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/e_logistics/all-license-categories`, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("FETCH_CATEGORIES", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCustomizeLicenseForm({ commit }, category_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${url()}/e_logistics/retrieve-customize-license-form/${category_id}`,
          {
            headers: {
              Authorization: `${Storage.get("token")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("FETCH_LiCENSE_FORMS", response.data.AllLicenseForms);
          }
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  fetchLicenseForms({ commit }, logisticId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/e_logistics/all-license-forms/${logisticId}`, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("FETCH_LiCENSE_FORMS", response.data.AllLicenseForms);
          }
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  addLicenseCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url()}/e_logistics/create-license-category`, data, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          // if (response.status === 200) {
          //   commit("ADD_LICENSE", response.data);
          // }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createLicenseForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url()}/e_logistics/create-customize-license-form`, data, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          // if (response.status === 200) {
          //   commit("ADD_LICENSE", response.data);
          // }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addLicenseForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url()}/e_logistics/upload-license-form`, data, {
          headers: {
            Authorization: `${Storage.get("token")}`,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("ADD_LICENSE_FORM", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateLicenseCategory({ commit }, license) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${url()}/e_logistics/update-license-category/${license.id}`,
          license.data,
          {
            headers: {
              Authorization: `${Storage.get("token")}`,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  updateLicenseForm({ commit }, license) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${url()}/api/e_logistics/update-license-form/${license.id}`,
          license.data,
          {
            headers: {
              Authorization: `${Storage.get("token")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("UPDATE_LICENSE_FORM", {
              id: license.id,
              content: response.data,
            });
          }
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  deleteLicenseCategory({ commit }, licenseId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${url()}/api/e_logistics/delete-license-category/${licenseId}`,
          {
            headers: {
              Authorization: `${Storage.get("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          resolve(response);
          commit("DELETE_LICENSE", licenseId);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteLicenseForm({ commit }, licenseId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${url()}/api/e_logistics/delete-license-form/${licenseId}`, {
          headers: {
            Authorization: `${Storage.get("token")}`,
          },
        })
        .then((response) => {
          console.log(response);
          resolve(response);
          commit("DELETE_LICENSE_FORM", licenseId);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export const mutations = {
  FETCH_LOGISTICS(state, logistics) {
    state.logistics = logistics;
  },
  FETCH_CATEGORIES(state, licenses) {
    state.categories = licenses;
  },
  // ADD_LICENSE(state, category) {
  //   state.categories.push(category);
  // },
  // UPDATE_LICENSE_CATEGORY(state, { id, category }) {
  //   state.categories = [
  //     ...state.categories.map((item) =>
  //       item.id !== id ? item : { ...item, ...category }
  //     ),
  //   ];
  // },
  DELETE_LICENSE(state, id) {
    index = state.categories.findIndex((license) => license.id == id);
    state.categories.splice(index, 1);
  },
  FETCH_LICENSE_FORMS(state, licenses) {
    state.forms = licenses;
  },
  ADD_LICENSE_FORM(state, license) {
    state.forms.push(license);
  },
  UPDATE_LICENSE_FORM(state, { id, content }) {
    state.forms.filter((license) =>
      license.id == id ? (content = license) : null
    );
  },
  DELETE_LICENSE_FORM(state, id) {
    index = state.forms.findIndex((license) => license.id == id);
    state.forms.splice(index, 1);
  },
};

export const getters = {
  alllogistics: (state) => state.logistics,
  alllicenses: (state) => state.licenses,
  allforms: (state) => state.forms,
};
