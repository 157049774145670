import axios from 'axios'
import url from '@/url'
import Storage from '@/helpers/storage.js'

export const state = {
    marketAdmins:[]
}

export const actions = {
     createMarketAdmin({commit},data) {
         return new Promise((resolve,reject)=> {
            // axios.defaults.headers.common['Authorization'] = `Token ${Storage.get('token')}`
             axios.post(`${url()}/admin/create`,data, {
                headers: {
                 'Authorization':Storage.get('token')
                }
            })
                .then(response => {
                    if(response.status === 201) {
                        const admin = response.data.admin
                        commit('add_marketadmin',admin)
                     }
                     resolve(response)
                }).catch(err => {
                    reject(err)
                })
         })
       },
    getAllMarketAdmins({commit}){
       return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
           axios.get(`${url()}/market-admins`)
            .then(response => {
                if(response.status === 200) {
                    commit('add_marketadmins',response.data)
                }
                resolve(response)
            }).catch(err => {
                console.log(err)
                reject(err)
            })
       })
    },
    suspendMarketAdmin({commit}, {id, key}) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
            axios.patch(`${url()}/market-admin-suspend/${id}`)
             .then(response => {
                 if(response.status === 200 ) {
                  commit('update_marketAdmin', {key:key, data:response.data.admin})   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    },
    unSuspendMarketAdmin({commit}, {id, key}) {
        return new Promise((resolve, reject) => {
            axios.patch(`${url()}/market-admin-unsuspend/${id}`, {
                headers: {
                 'Authorization':Storage.get('token')
                }
            })
             .then(response => {
                 if(response.status === 200 ) {
                  commit('update_marketAdmin', {key:key, data:response.data.admin})   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    }
}

export const mutations = {
    add_marketadmin(state,admin) {
        state.marketAdmins.push(admin)

    },
    add_marketadmins(state,admins){
        state.marketAdmins = admins
    
    },
    update_marketAdmin(state,{key,data}) {
        state.marketAdmins[key] = data
    }
}

export const getters = {
    marketAdmins: state => state.marketAdmins
}